import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { BASE_URL } from '../configs/configs';
import { orderAdd, orderById, orderStatusUpdate } from './ordersActions';

import Axios from '../services/Axios';
import useAuth from '../services/useAuth';
import useAxiosAuth from '../services/useAxiosAuth';
import useSquare from '../services/useSquare';

const purchaseIntentUrl = BASE_URL + '/order/purchase-intent';
const pendingByIdUrl = BASE_URL + '/order/order-by-id';
const statusUrl = BASE_URL + '/order/order-status';

const urlLogin = '/auth/login';
const urlSignup = '/auth/signup';
const urlRefreshToken = '/auth/refresh-token';

const urlSquareDevicesCodesList = `${BASE_URL}/square/devices-codes/list`;
const urlSquareDevicesCodesCreate = `${BASE_URL}/square/devices-codes/create`;
const urlSquareCreateTerminalCheckout = `${BASE_URL}/square/terminal-checkout/create`;
const urlSquareUpdateStatus = `${BASE_URL}/square/terminal-checkout/status`;

const urlSquareOnlineCheckoutCreate = `${BASE_URL}/square/online-checkout/create`;
// const urlSquareOnlineCheckoutStatusUpdate = `${BASE_URL}/square/online-checkout/status`;

// keys
export const qKeys = {
  order: ['order'],
  pending: (orderId) => [...qKeys.order, 'pending', orderId],
  status: (orderId) => [...qKeys.order, 'status', orderId],
  square: ['square'],
  devices: (squareLocId) => [...qKeys.square, 'devices', squareLocId],
  terminal: (squareLocId) => [...qKeys.square, 'terminal', squareLocId],
  checkoutStatus: checkoutId => [...qKeys.square, 'terminal', checkoutId]
};

// Queries
export const useOrderStatus = orderId => {
  const { tsUpdated, status } = orderById(orderId);
  return useQuery(
    qKeys.status(orderId),
    () => fetchStatus(orderId),
    {
      initialData: () => ({ id: orderId, tsUpdated, status }),
      // update order status at local storage 'orders'
      onSuccess: data => {
        orderStatusUpdate(data);
      }
    });
};

export const usePendingOrder = orderId => {
  const order = orderById(orderId);
  return useQuery(
    qKeys.pending(orderId),
    () => fetchPending(orderId),
    {
      initialData: () => order
    });
};

// Mutations
export const useOrderCreate = () => {
  return useMutation(chkout => axios.post(purchaseIntentUrl, chkout));
};

// Helpers
const fetchPending = async orderId => {
  const res = await axios.get(pendingByIdUrl + '/' + orderId);
  console.log('fetchPending res', res);
  return res.data;
};

const fetchStatus = async orderId => {
  const res = await axios.get(statusUrl + '/' + orderId);
  return res.data;
};

/* Auth */
/*
  creds = {email, password, location}
*/
export const useSignup = () => {
  return useMutation(creds => Axios.post(urlSignup, creds));
};

/*
  creds = {email, password, location, role}
*/
export const useLogin = () => {
  const { setAuth } = useAuth();
  const fn = creds => Axios.post(urlLogin, creds);
  const onSuccess = ({ data }) => {
    const { accessToken, refreshToken } = data;
    const { exp, name, email, location, role } = jwtDecode(accessToken);
    setAuth({ accessToken, refreshToken, exp, name, email, location, role });
  };

  return useMutation(fn, { onSuccess, onError: console.error });
};

/*
  refreshToken = current valid refreshToken
*/
export const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const fn = refreshToken => Axios.post(urlRefreshToken, { refreshToken });
  const onSuccess = ({ data }) => {
    const { accessToken, refreshToken } = data;
    const { exp, name, email, location, role } = jwtDecode(accessToken);
    setAuth({ accessToken, refreshToken, exp, name, email, location, role });
  };
  return useMutation(fn, { onSuccess, onError: console.error });
};

/* SQUARE ONLINE CHECKOUT */
// Create Online Checkout
export const useCreateOnlineCheckout = () => {
  const { setSquare } = useSquare();

  const fn = (order) => axios.post(urlSquareOnlineCheckoutCreate, order);

  const onSuccess = ({ data }) => {
    const { squareCheckoutId, squareCheckoutUrl, squareCheckoutStatus } = data;
    console.log('===data', squareCheckoutId, squareCheckoutUrl, squareCheckoutStatus);
    setSquare(prev => ({ ...prev, squareCheckoutId, squareCheckoutUrl, squareCheckoutStatus }));
  };
  return useMutation(fn, { onSuccess, onError: console.error });
};

export const useOnlineCheckoutStatus = () => {
  const { square, setSquare } = useSquare();
  const { squareCheckoutId } = square;

  // TOBE reviewd: use the same route for terminal-status
  const fn = () => axios.get(`${urlSquareUpdateStatus}/${squareCheckoutId}`);

  const onSuccess = data => {
    // to update checkoutStatus
    const { squareCheckoutStatus } = data;
    setSquare(prev => ({ ...prev, squareCheckoutStatus }));
  };

  const onError = console.log;

  const select = res => res.data; //{ squareCheckoutId, squareCheckoutStatus, squareAmount, squareReferenceId }

  return useQuery(qKeys.checkoutStatus(squareCheckoutId), fn,
    { onSuccess, onError, select, refetchInterval: 5000 }
  );
};


// Square Queries
export const useDeviceCodesList = squareLocId => {
  console.log('useDeviceCodesList squareLocId', squareLocId);
  const axiosAuth = useAxiosAuth();
  const fn = () => axiosAuth.get(`${urlSquareDevicesCodesList}/${squareLocId}`);
  return useQuery(
    qKeys.devices(squareLocId),
    fn,
    {
      onError: console.log,
      select: data => {
        if (!data.data) return [];

        if (process.env.NODE_ENV === 'development') {
          console.log('development data', data);
          return data.data.map(dc => ({ ...dc, status: 'PAIRED' }));
        }
        return data.data;
      }
    });
};

// Mutations
/* payload 
  reponse 200
  {
  "device_code": {
    "id": "2KXRQPC1WBEF7",
    "name": "PR01",
    "code": "NBSNAN",
    "product_type": "TERMINAL_API",
    "location_id": "L3V651AXSTD6K",
    "pair_by": "2022-06-16T06:29:39.000Z",
    "created_at": "2022-06-16T06:24:39.000Z",
    "status": "UNPAIRED",
    "status_changed_at": "2022-06-16T06:24:39.000Z"
  }
}
*/
export const useSquareDevicesCodesCreate = () => {
  const axiosAuth = useAxiosAuth();
  const queryClient = useQueryClient();

  const fn = ({ squareLocId, name }) => axiosAuth.post(urlSquareDevicesCodesCreate, { squareLocId, name });
  const onSuccess = ({ data }) => {
    queryClient.invalidateQueries(qKeys.devices(data.location_id));
  };
  return useMutation(fn, { onSuccess, onError: console.error });
};

// Create Terminal Checkout
export const useCreateTerminalCheckout = () => {
  const { setSquare } = useSquare();

  const axiosAuth = useAxiosAuth();

  const fn = (order) => axiosAuth.post(urlSquareCreateTerminalCheckout, order);

  const onSuccess = ({ data }) => {
    const { squareCheckoutId, squareCheckoutStatus } = data;
    setSquare(prev => ({ ...prev, squareCheckoutStatus, squareCheckoutId }));
  };
  return useMutation(fn, { onSuccess, onError: console.error });
};

export const useTerminalCheckoutStatus = () => {
  const { square, setSquare } = useSquare();
  const { squareCheckoutId } = square;

  const axiosAuth = useAxiosAuth();

  const fn = () => axiosAuth.get(`${urlSquareUpdateStatus}/${squareCheckoutId}`);

  const onSuccess = data => {
    // to update checkoutStatus
    const { squareCheckoutStatus } = data;
    setSquare(prev => ({ ...prev, squareCheckoutStatus }));
  };

  const onError = console.log;

  const select = res => res.data; //{ squareCheckoutId, squareCheckoutStatus, squareAmount, squareReferenceId }

  return useQuery(qKeys.checkoutStatus(squareCheckoutId), fn,
    { onSuccess, onError, select, refetchInterval: 5000 }
  );
};

export const useTrackingOrderById = orderId => {
  console.log('pendingByIdUrl/orderId', pendingByIdUrl + '/' + orderId);
  return useQuery(
    qKeys.pending(orderId),
    () => fetchPending(orderId),
    {
      placeholderData: {},
      onSuccess: order => {
        console.log('useTrackingOrderById success', order);
        const {
          cart,
          id,
          orderNumber,
          locId,
          locAddress,
          tsCreated,
          tsUpdated,
          tsPickup,
          status,
          statusName,
          squareCheckoutId
        } = order;
        orderAdd({
          cart,
          id,
          orderNumber,
          locId,
          locAddress,
          tsCreated,
          tsUpdated,
          tsPickup,
          status,
          statusName,
          squareCheckoutId
        }); // is fetched order by orderId
      },
      onError: console.log
    }
  );
};

export const useTrackingOrderStatusById = orderId => {
  return useQuery(
    qKeys.status(orderId),
    () => fetchStatus(orderId),
    {
      placeholderData: { id: orderId },
      // update order status at local storage 'orders'
      onSuccess: data => {
        orderStatusUpdate(data);
      },
      refetchInterval: 180000 // 3minutes
    });
};