import { Link, useLocation } from 'react-router-dom';
import { useToast, Center, HStack, Text } from '@chakra-ui/react';
import { FlexBar, IconHistory, IconList } from '../common';
import { Image } from "@chakra-ui/react";
import ChkoutLinkWithNoButton from './ChkoutLinkWithNoButton';
// import lighthouse from '../assets/portroll2.svg'
import lighthouse from '../assets/logo.svg';
// import lighthouse from '../assets/portroll.svg'
import { getVersions } from '../states/useVersionsStore';
import { getLocId } from '../states/locationActions';

const infoToast = toast => message => {
  toast({
    title: message,
    status: 'info',
    duration: 5000,
    isClosable: true,
    position: 'top'
  });
};

const instore = process.env.REACT_APP_PAYMENT_TYPE === 'instore';

const Header = () => {
  const toast = useToast();
  const info = infoToast(toast);

  const loc = useLocation();
  const isCheckout = () => loc.pathname === '/checkout';

  const { versions: { appVersion, dataVersion } } = getVersions();

  const imageClick = () => info(`Versions: ${appVersion}-${dataVersion} | ${getLocId()}`);

  if (isCheckout()) {
    return <HeaderCheckout />;
  }
  return (
    <FlexBar>
      <Center><Image onClick={imageClick} p='0' h='60px' src={lighthouse} /></Center>
      <Text fontSize='1.4rem' fontWeight='600' color='red.700' onClick={imageClick}>
        HAPPY ROLL
      </Text>
      {
        instore &&
        <Text fontSize='1.6rem' fontWeight='600' color='gray.700'>
          INSTORE ORDERING
        </Text>
      }
      <HStack>
        {!instore && <Link to='/orders'><IconHistory /></Link>}
        <Link to='/'><IconList /></Link>
        <ChkoutLinkWithNoButton />
      </HStack>
    </FlexBar>
  );
};
export default Header;

const HeaderCheckout = () => {
  return (
    <FlexBar>
      <Center w='full' p='3'>
        <Text fontWeight='600' fontSize='2xl' color='red.600'>
          Checkout
        </Text>
      </Center>
    </FlexBar>
  );
};
